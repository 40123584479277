<template>
  <div>
    <div class="mb-1" style="overflow-x: auto; white-space: nowrap">
      <b-button
        class="ml-50"
        :variant="
          selectedButton != 'reports' ? 'outline-primary' : 'relief-primary'
        "
        pill
        @click="selectedButton = 'reports'"
      >
        {{ lang("t_reports") }}
      </b-button>
      <b-button
        class="ml-50"
        :variant="
          selectedButton != 'report_files'
            ? 'outline-primary'
            : 'relief-primary'
        "
        pill
        @click="selectedButton = 'report_files'"
      >
        {{ lang("t_reportFiles") }}
      </b-button>
    </div>
    <div>
      <report v-if="selectedButton == 'reports'"></report>
      <report-files v-if="selectedButton == 'report_files'"></report-files>
    </div>
  </div>
</template>

<script>
import { BFormRadioGroup, BFormGroup, BRow, BCol, BCard, BCardText, BLink, BButton } from 'bootstrap-vue'
// import SmsSender from './SmsSender.vue'

import Report from './Reports.vue'
import ReportFiles from './ReportFiles.vue'

export default {
  data() {
    return {
      lang: _l(window.localStorage.getItem('language') || "tr"),
      selectedButton: 'reports',
    }
  },
  components: {
    Report,
    ReportFiles,
    BFormRadioGroup,
    BFormGroup,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BButton,
  },

}
</script>

<style>
</style>
