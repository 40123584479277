<template>
  <div>
    <b-custom-spinner :is_progress="is_progress"></b-custom-spinner>
    <!-- Report Body -->
    <b-card style="max-height: 78vh">
      <b-row align-h="between">
        <b-col></b-col>
        <b-col md="4" class="my-1">
          <b-form-group class="mb-0">
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                :placeholder="lang('t_search')"
              />
              <b-input-group-append>
                <b-button
                  variant="outline-primary"
                  :disabled="!filter"
                  @click="filter = ''"
                >
                  {{ lang("t_clear") }}
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row style="max-width: 100%; overflow-x: auto" class="mx-0 px-0">
        <b-col cols="12" class="mx-0 px-0">
          <b-table
            style="height: 60vh"
            bordered
            striped
            hover
            responsive="sm"
            :items="items"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
          >
            <template #cell(Action)="data">
              <center>
                <b-button
                  v-if="data.item.Status == 'Done'"
                  size="sm"
                  :href="`${PROTOCOL}://${API_URL}/report/v1/ReportsInQueueById/${data.item._id}`"
                  variant="outline-primary"
                >
                  <feather-icon icon="DownloadIcon"></feather-icon>
                </b-button>
              </center>
            </template>
            <template #cell(Status)="data">
              <center>
                <b-badge pill style="width: 90%" :variant="colors[data.value]">
                  {{ description[data.value] }}
                </b-badge>
              </center>
            </template>
            <template #cell(Filter)="data">
              {{
                `Başlangıç T: ${new Date(data.value.startDate)
                  .toISOString()
                  .replace(
                    /([^T]+)T([^\.]+).*/g,
                    "$1 $2"
                  )}, Bitiş T: ${new Date(data.value.endDate)
                  .toISOString()
                  .replace(/([^T]+)T([^\.]+).*/g, "$1 $2")}`
              }}
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BBadge,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BTable,
  BFormGroup,
  BRow,
  BCol,
  BCard,
  BCardText,
} from "bootstrap-vue";
import vSelect from "vue-select";

export default {
  data() {
    return {
      lang: _l(window.localStorage.getItem("language") || "tr"),
      is_progress: false,
      items: [],
      fields: [
        {
          key: "CreateDate",
          label: globalThis._lang("t_date"),
          sortable: true,
        },
        {
          key: "Username",
          label: globalThis._lang("t_creator"),
          sortable: true,
        },
        // {
        //   key: "Filter",
        //   label: globalThis._lang("t_filters"),
        //   sortable: true,
        // },
        {
          key: "ReportName",
          label: globalThis._lang("t_reportName"),
          sortable: true,
        },
        {
          key: "DataCount",
          label: globalThis._lang("t_dataCount"),
          sortable: true,
        },
        {
          key: "Status",
          label: globalThis._lang("t_status"),
          sortable: true,
        },
        {
          key: "Action",
          label: globalThis._lang("t_action"),
          sortable: true,
        },
      ],
      currentPage: 1,
      sortBy: "CreateDate",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      colors: {
        Failed: "danger",
        "In Queue": "warning",
        "In Progress": "primary",
        Done: "success",
      },
      description: {
        Failed: globalThis._lang("t_failed"),
        "In Queue": globalThis._lang("t_rInQueue"),
        "In Progress": globalThis._lang("t_rInProgress"),
        Done: globalThis._lang("t_isCompleted"),
      },
      PROTOCOL: globalThis.env.PROTOCOL,
      API_URL: globalThis.env.API_URL,
    };
  },
  components: {
    BBadge,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BTable,
    BFormGroup,
    BRow,
    BButton,
    BCol,
    BCard,
    BCardText,
    vSelect,
  },

  mounted: async function () {
    this.is_progress = true;

    await this.GetReportsInQueue();

    this.is_progress = false;
  },

  methods: {
    b64toBlob: (b64Data, contentType = "", sliceSize = 512) => {
      const byteCharacters = window.atob(b64Data);
      const byteArrays = [];

      for (
        let offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, { type: contentType });
      return blob;
    },
    GetReportsInQueue: async function () {
      var response = (await this.$http_in.get(`report/v1/ReportsInQueue`)).data;

      this.items = response;
    },
    Download: async function (item) {
      var response = Object.values((await this.$http_in.get()).data);

      // var decoder = new TextDecoder('utf8');
      // var b64 = new Blob(Uint8Array.from(response), {
      //   type: ''
      // })

      // const url = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${btoa(String.fromCharCode.apply(null, Uint8Array.from(response)))}`;
      // const link = document.createElement('a')
      // link.href = url
      // link.setAttribute('download', item.FileName);
      // document.body.appendChild(link);
      // link.click();
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
