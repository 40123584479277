<template>
  <div>
    <b-custom-spinner :is_progress="is_progress"></b-custom-spinner>
    <b-card-actions
      v-if="!full_screen"
      action-collapse
      :title="lang('t_reportDetails')"
    >
      <b-row>
        <b-col>
          <b-form-group>
            <v-select
              :placeholder="lang('t_category')"
              v-model="selected_category"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="display_name"
              :reduce="(item) => item.internal_name"
              :options="categories"
              @input="clear_report"
            /> </b-form-group
        ></b-col>
        <b-col>
          <b-form-group>
            <v-select
              :placeholder="lang('t_report')"
              v-model="selected_report"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="display_name"
              :options="
                report_list.filter((e) => e.category == selected_category)
              "
              @input="clear_data"
            /> </b-form-group
        ></b-col>
        <b-col>
          <b-form-group>
            <v-select
              v-model="selected_date_filter_type"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="display_name"
              :reduce="(item) => item.internal_name"
              :options="date_filter_types"
              @input="date_filter_type_changed"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group>
            <flat-pickr
              v-model="filter_values.startDate"
              class="form-control"
              :disabled="selected_date_filter_type != 'between_dates'"
              :config="{
                enableTime: true,
                dateFormat: 'Y-m-d H:i',
                allowInput: true,
                time_24hr: true,
              }"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group>
            <flat-pickr
              v-model="filter_values.endDate"
              class="form-control"
              :disabled="selected_date_filter_type != 'between_dates'"
              :config="{
                dateFormat: 'Y-m-d H:i',
                enableTime: true,
                allowInput: true,
                time_24hr: true,
              }"
            />
            <!-- dateFormat: 'Y-m-d H:i', -->
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col :key="i" v-for="(item, i) in selected_report.filters">
          <b-form-group v-if="item.internal_name == 'QualityForm'">
            <v-select
              :closeOnSelect="false"
              v-model="filter_values.selectedQualityForms"
              multiple
              :placeholder="lang(item.display_name)"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="display_name"
              :reduce="(item) => item._id"
              :options="quality_forms"
            />
          </b-form-group>
          <b-form-group v-if="item.internal_name == 'Project'">
            <v-select
              :closeOnSelect="false"
              v-model="filter_values.selectedProjects"
              multiple
              :placeholder="lang(item.display_name)"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="display_name"
              :reduce="(item) => item.internal_name"
              :options="projects"
            />
          </b-form-group>
          <b-form-group v-if="item.internal_name == 'Queue'">
            <v-select
              :closeOnSelect="false"
              v-model="filter_values.selectedQueues"
              multiple
              :placeholder="lang(item.display_name)"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="display_name"
              :reduce="(item) => item.internal_name"
              :options="queues"
            />
          </b-form-group>
          <b-form-group v-if="item.internal_name == 'Campaign'">
            <v-select
              :closeOnSelect="false"
              v-model="filter_values.selectedCampaigns"
              multiple
              :placeholder="lang(item.display_name)"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="display_name"
              :reduce="(item) => item.internal_name"
              :options="campaigns"
            />
          </b-form-group>
          <b-form-group v-if="item.internal_name == 'Line'">
            <v-select
              :closeOnSelect="false"
              v-model="filter_values.selectedLines"
              multiple
              :placeholder="lang(item.display_name)"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="display_name"
              :reduce="(item) => item.internal_name"
              :options="lines"
            />
          </b-form-group>
          <b-form-group v-if="item.internal_name == 'IVR'">
            <v-select
              v-model="filter_values.selectedIvr"
              :placeholder="lang(item.display_name)"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="display_name"
              :reduce="(item) => item.internal_name"
              :options="ivr_list"
            />
          </b-form-group>
          <b-form-group v-if="item.internal_name == 'Agent'">
            <v-select
              :closeOnSelect="false"
              v-model="filter_values.selectedAgents"
              multiple
              :placeholder="lang(item.display_name)"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="username"
              :reduce="(item) => item.username"
              :options="agents"
            />
          </b-form-group>
          <b-form-group v-if="item.internal_name == 'RemoteNumber'">
            <b-form-input
              v-model="filter_values.selectedNumber"
              :placeholder="lang(item.display_name)"
              @update="
                () => {
                  filter_values.selectedNumber = filter_values.selectedNumber
                    .split(' ')
                    .join('');
                }
              "
              @input="
                () => {
                  filter_values.selectedNumber = filter_values.selectedNumber
                    .split(' ')
                    .join('');
                }
              "
            />
          </b-form-group>
          <b-form-group v-if="item.internal_name == 'SLDuration'">
            <b-form-input
              type="number"
              v-model="filter_values.selectedSL"
              :placeholder="lang(item.display_name)"
              @update="
                () => {
                  filter_values.selectedSL = filter_values.selectedSL
                    .split(' ')
                    .join('');
                }
              "
              @input="
                () => {
                  filter_values.selectedSL = filter_values.selectedSL
                    .split(' ')
                    .join('');
                }
              "
            />
          </b-form-group>
          <b-form-group v-if="item.internal_name == 'title'">
            <b-form-input
              v-model="filter_values.title"
              :placeholder="lang(item.display_name)"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            v-if="selected_report.internal_name == 'DataSummaryByDynamicFields'"
          >
            <v-select
              v-model="filter_values.selectedDynamicCRMColumns.field1"
              :placeholder="lang('t_primaryKey')"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="text"
              :reduce="(item) => item.value"
              :options="project_columns"
            >
            </v-select>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            v-if="selected_report.internal_name == 'DataSummaryByDynamicFields'"
          >
            <v-select
              v-model="filter_values.selectedDynamicCRMColumns.field2"
              :placeholder="lang('t_secondaryKey')"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="text"
              :reduce="(item) => item.value"
              :options="project_columns"
            >
            </v-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          v-if="selected_report.internal_name == 'QualityDetails'"
          cols="3"
        >
          <b-form-checkbox
            v-model="filter_values.useDatesForEvaluateDate"
            class="custom-control-primary mt-75"
          >
            <span class="vs-checkbox">
              <span class="vs-checkbox--check">
                <i class="vs-icon feather icon-check" />
                {{ lang("t_useDatesForEvaluateDate") }}
              </span>
            </span>
          </b-form-checkbox>
        </b-col>
        <b-col>
          <b-form-group
            v-if="
              selected_report.internal_name == 'CallDetails' ||
              selected_report.internal_name == 'QualityDetails'
            "
          >
            <v-select
              v-model="filter_values.selectedCustomerColumns"
              multiple
              :placeholder="lang('t_addExtraCustomerInfo')"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="text"
              :reduce="(item) => item"
              :options="project_columns"
            >
              <template #list-header>
                <div class="w-100 mb-50">
                  <b-button
                    class="w-50"
                    size="sm"
                    variant="outline-primary"
                    @click="
                      () => {
                        filter_values.selectedCustomerColumns = project_columns;
                      }
                    "
                  >
                    <feather-icon size="14" icon="CheckCircleIcon" />
                    {{ lang("t_selectAll") }}
                  </b-button>
                  <b-button
                    class="w-50"
                    size="sm"
                    variant="outline-danger"
                    @click="
                      () => {
                        filter_values.selectedCustomerColumns = [];
                      }
                    "
                  >
                    <feather-icon size="14" icon="XOctagonIcon" />
                    {{ lang("t_cleanSelections") }}
                  </b-button>
                </div>
              </template>
            </v-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-button @click="getReportData" class="float-right" variant="primary">
        {{ lang("t_get") }}</b-button
      >
      <b-button
        v-if="items.length > 0 && items.length < 499"
        @click="onexport"
        class="float-right mr-1"
        variant="success"
      >
        {{ lang("t_export") }}</b-button
      >
    </b-card-actions>

    <!-- Report Body -->
    <b-card :style="{ height: full_screen ? '76vh' : '58vh' }">
      <b-card-text>
        <b-row align-h="between">
          <b-col cols="4" class="my-1">
            <b-img
              v-if="selected_category == 'callexper' && items.length > 0"
              :style="{
                filter: skin == 'dark' ? 'brightness(0) invert(1)' : '',
              }"
              width="100vh"
              src="logo_callexper.png"
              alt="logo"
            />
          </b-col>
          <b-col md="4" class="my-1">
            <b-button-group class="float-right w-100">
              <b-form-input v-model="filter" :placeholder="lang('t_search')" />
              <b-button
                class=""
                variant="flat-primary"
                @click="full_screen = !full_screen"
              >
                <feather-icon
                  :icon="!full_screen ? 'Maximize2Icon' : 'Minimize2Icon'"
                ></feather-icon>
              </b-button>
            </b-button-group>
          </b-col>
        </b-row>
      </b-card-text>
      <b-card-text>
        <b-row class="mx-0 px-0">
          <b-col cols="12" class="mx-0 px-0">
            <b-table
              :style="{
                height: full_screen ? '60vh' : '44vh',
                overflowX: 'auto',
              }"
              small
              bordered
              striped
              hover
              responsive
              :items="items"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
            >
              <!-- :per-page="perPage"
            :current-page="currentPage" -->
              <template #cell(RecordingFile)="data">
                {{ data.value.split("-").join("/").replace(/ /g, "\u00a0") }}
              </template>
              <!-- <template #cell(RecordingFileName)="data">
              <center>
                <b-button
                  v-if="data.item.RecordingFile != ''"
                  size="sm"
                  @click="openAudio(data.item)"
                  variant="outline-primary"
                >
                  <feather-icon icon="PlayIcon"></feather-icon>
                </b-button>
              </center>
            </template> -->
              <template #cell(RecordingFilePath)="data">
                <center>
                  <b-button
                    v-if="data.item.RecordingFilePath != ''"
                    size="sm"
                    @click="openSecLineAudio(data.item)"
                    variant="outline-primary"
                  >
                    <feather-icon icon="PlayIcon"></feather-icon>
                  </b-button>
                </center>
              </template>
              <template #cell(Note)="data">
                <center>
                  <b-button
                    v-if="data.item.Note && data.item.Note.length > 0"
                    size="sm"
                    @click="openNote(data.item)"
                    variant="outline-primary"
                  >
                    <feather-icon icon="InfoIcon"></feather-icon>
                  </b-button>
                </center>
              </template>
              <template #cell(note)="data">
                <center>
                  <b-button
                    v-if="data.item.note && data.item.note.length > 0"
                    size="sm"
                    @click="openNote(data.item)"
                    variant="outline-primary"
                  >
                    <feather-icon icon="InfoIcon"></feather-icon>
                  </b-button>
                </center>
              </template>
              <template #cell(Queue)="data">
                {{
                  queues.find((e) => e.internal_name == data.value)
                    ? queues
                        .find((e) => e.internal_name == data.value)
                        .display_name.replace(/ /g, "\u00a0")
                    : data.value
                }}
              </template>
              <template #cell(queue)="data">
                {{
                  queues.find((e) => e.internal_name == data.value)
                    ? queues
                        .find((e) => e.internal_name == data.value)
                        .display_name.replace(/ /g, "\u00a0")
                    : data.value
                }}
              </template>
              <template #cell(Body)="data">
                <span v-html="data.value"></span>
              </template>
              <template #cell(Kuyruk)="data">
                {{
                  queues.find((e) => e.internal_name == data.value)
                    ? queues
                        .find((e) => e.internal_name == data.value)
                        .display_name.replace(/ /g, "\u00a0")
                    : data.value
                }}
              </template>
              <template #cell(CalledBackedQueue)="data">
                {{
                  queues.find((e) => e.internal_name == data.value)
                    ? queues
                        .find((e) => e.internal_name == data.value)
                        .display_name.replace(/ /g, "\u00a0")
                    : data.value
                }}
              </template>
              <template #cell(IsCalledBack)="data">
                <center>
                  <feather-icon
                    :icon="data.value ? 'CheckCircleIcon' : 'XIcon'"
                    size="14"
                    :class="data.value ? 'text-success' : 'text-danger'"
                  />
                </center>
              </template>
              <template #cell(Campaign)="data">
                {{
                  campaigns.find((e) => e.internal_name == data.value)
                    ? campaigns
                        .find((e) => e.internal_name == data.value)
                        .display_name.replace(/ /g, "\u00a0")
                    : data.value
                }}
              </template>
              <template #cell(Status)="data">
                {{
                  statu_description.hasOwnProperty(data.value)
                    ? statu_description[data.value]
                    : data.value
                }}
              </template>
            </b-table>
          </b-col>
        </b-row>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import {
  BImg,
  BButtonGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BTable,
  BFormGroup,
  BRow,
  BCol,
  BCard,
  BFormCheckbox,
  BCardText,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "animate.css";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";

export default {
  data() {
    return {
      lang: _l(window.localStorage.getItem("language") || "tr"),
      is_progress: false,
      excel_url: "",
      full_screen: false,
      filter_values: {
        startDate: new Date(
          new Date(new Date().setHours(0, 0, 0, 0)).getTime() +
          3 * 60 * 60 * 1000
        )
          .toISOString()
          .replace(/([^T]+)T([^\.]+).*/g, "$1 $2"),
        endDate: new Date(
          new Date(new Date().setHours(23, 59, 59, 0)).getTime() +
          3 * 60 * 60 * 1000
        )
          .toISOString()
          .replace(/([^T]+)T([^\.]+).*/g, "$1 $2"),
        selectedAgents: [],
        selectedQueues: [],
        selectedProjects: [],
        selectedQualityForms: [],
        selectedCampaigns: [],
        selectedCustomerColumns: [],
        selectedDynamicCRMColumns: {
          field1: "",
          field2: "",
        },
        selectedIvr: "",
        title: "",
        selectedNumber: "",
        selectedSL: 20,
        isFileNotNull: false,
        useDatesForEvaluateDate: false,
      },
      audio_modal: false,
      audio2: "",
      htmlcontent: "",
      selected_note: "",
      selected_audio: "",
      agents: [],
      projects: [],
      queues: [],
      quality_forms: [],
      ivr_list: [],
      selected_date_filter_type: "today",
      selected_category: "",
      selected_report: "",
      categories: [
        { internal_name: "queue", display_name: globalThis._lang("t_queue") },
        { internal_name: "agent", display_name: globalThis._lang("t_agent") },
        {
          internal_name: "verdict",
          display_name: globalThis._lang("t_finishCode"),
        },
        {
          internal_name: "classification",
          display_name: globalThis._lang("t_classification"),
        },
        { internal_name: "crm", display_name: globalThis._lang("t_crm") },
        { internal_name: "quality", display_name: globalThis._lang("t_quality") },
        { internal_name: "other", display_name: globalThis._lang("t_other") },
        { internal_name: "callexper", display_name: "Callexper" },
      ],
      report_list: [],
      date_filter_types: [
        {
          internal_name: "today",
          display_name: globalThis._lang("t_today"),
        },
        {
          internal_name: "yesterday",
          display_name: globalThis._lang("t_yesterday"),
        },
        {
          internal_name: "last_7_days",
          display_name: globalThis._lang("t_last7Days"),
        },
        {
          internal_name: "last_30_days",
          display_name: globalThis._lang("t_last30Days"),
        },
        {
          internal_name: "this_month",
          display_name: globalThis._lang("t_thisMonth"),
        },
        {
          internal_name: "last_month",
          display_name: globalThis._lang("t_lastMonth"),
        },
        {
          internal_name: "between_dates",
          display_name: globalThis._lang("t_betweenDates"),
        },
      ],
      perPage: 50,
      pageOptions: [10, 50, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [],
      items: [],
      project_columns: [],
      report_permissions: [],
      column_labels: {
        status: "Durum",
        assigned_agent: "Agent",
        attempts: "Temas",
        total_attempts: "Toplam Temas",
        campaign: "Kampanya",
        finish_code: "Son Sonuç Kodu",
        insert_date: "Arama Tarihi",
        action_date: "Aranacak Tarih",
        action_time: "Aranacak Zaman",
        create_date: "Yüklenme Tarihi",
        create_time: "Yüklenme Zamanı",
        ex_agent: "Ex Agent",
        assign_date: "Atama Tarihi",
      },
      statu_description: {
        C: "Aranabilir",
        E: "Manual Olarak Elendi",
        I: "Aranıyor",
        K: "Arama Kuralına Göre Kapatıldı",
        U: "Ulaşıldı",
      },
    };
  },
  components: {
    BImg,
    BFormCheckbox,
    BCardActions,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BTable,
    flatPickr,
    BFormGroup,
    BRow,
    BButton,
    BCol,
    BCard,
    BCardText,
    vSelect,
    BButtonGroup,
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  mounted() {
    this.is_progress = true;
    this.report_permissions = globalThis.permissions["report"] || [];

    const this_data = JSON.parse(localStorage.getItem("report_cache"));
    if (![undefined, null].includes(this_data)) {
      for (let [key, val] of Object.entries(this_data)) {
        this[key] = val;
      }
    }
    this.full_screen = false;

    this.getCustomerColumns();
    this.GetReports();
    this.get_users();
    this.GetProjects();
    this.GetQueues();
    this.GetCampaigns();
    this.GetQualityForms();
    this.GetIVR();
    this.GetLine();

    this.is_progress = false;
    // this.GetInboundRouteTargets();
  },
  beforeDestroy() {
    localStorage.setItem("report_cache", JSON.stringify(this.$data));
  },
  methods: {
    formatName(value) {
      return value || "-";
    },
    clear_report() {
      this.fields = [];
      this.items = [];
      this.filter_values = {
        ...this.filter_values,
        // startDate: new Date(new Date((new Date().setHours(0, 0, 0, 0))).getTime() + 3 * 60 * 60 * 1000).toISOString().replace(/([^T]+)T([^\.]+).*/g, '$1 $2'),
        // endDate: new Date(new Date((new Date().setHours(23, 59, 59, 0))).getTime() + 3 * 60 * 60 * 1000).toISOString().replace(/([^T]+)T([^\.]+).*/g, '$1 $2'),
        selectedAgents: [],
        selectedQueues: [],
        selectedProjects: [],
        selectedQualityForms: [],
        selectedCampaigns: [],
        selectedCustomerColumns: [],
        selectedDynamicCRMColumns: {
          field1: "",
          field2: "",
        },
        selectedIvr: "",
        title: "",
        selectedNumber: "",
        selectedSL: 20,
        isFileNotNull: false,
      };

      this.selected_report = "";
    },
    clear_data() {
      this.fields = [];
      this.items = [];
      // this.selected_date_filter_type == 'today';
      this.filter_values = {
        ...this.filter_values,
        // startDate: new Date(new Date((new Date().setHours(0, 0, 0, 0))).getTime() + 3 * 60 * 60 * 1000).toISOString().replace(/([^T]+)T([^\.]+).*/g, '$1 $2'),
        // endDate: new Date(new Date((new Date().setHours(23, 59, 59, 0))).getTime() + 3 * 60 * 60 * 1000).toISOString().replace(/([^T]+)T([^\.]+).*/g, '$1 $2'),
        selectedAgents: [],
        selectedQueues: [],
        selectedProjects: [],
        selectedCampaigns: [],
        selectedCustomerColumns: [],
        selectedDynamicCRMColumns: {
          field1: "",
          field2: "",
        },
        selectedIvr: "",
        title: "",
        selectedNumber: "",
        selectedSL: 20,
        isFileNotNull: false,
      };
    },
    date_filter_type_changed() {
      if (this.selected_date_filter_type == "today") {
        this.filter_values.startDate = new Date(
          new Date(new Date().setHours(0, 0, 0, 0)).getTime() +
          3 * 60 * 60 * 1000
        )
          .toISOString()
          .replace(/([^T]+)T([^\.]+).*/g, "$1 $2");
        this.filter_values.endDate = new Date(
          new Date(new Date().setHours(23, 59, 59, 0)).getTime() +
          3 * 60 * 60 * 1000
        )
          .toISOString()
          .replace(/([^T]+)T([^\.]+).*/g, "$1 $2");
      }
      if (this.selected_date_filter_type == "yesterday") {
        this.filter_values.startDate = new Date(
          new Date(new Date().setHours(0, 0, 0, 0)).getTime() +
          3 * 60 * 60 * 1000 -
          24 * 60 * 60 * 1000
        )
          .toISOString()
          .replace(/([^T]+)T([^\.]+).*/g, "$1 $2");
        this.filter_values.endDate = new Date(
          new Date(new Date().setHours(23, 59, 59, 0)).getTime() +
          3 * 60 * 60 * 1000 -
          24 * 60 * 60 * 1000
        )
          .toISOString()
          .replace(/([^T]+)T([^\.]+).*/g, "$1 $2");
      }
      if (this.selected_date_filter_type == "last_7_days") {
        this.filter_values.startDate = new Date(
          new Date(new Date().setHours(0, 0, 0, 0)).getTime() +
          3 * 60 * 60 * 1000 -
          7 * 24 * 60 * 60 * 1000
        )
          .toISOString()
          .replace(/([^T]+)T([^\.]+).*/g, "$1 $2");
        this.filter_values.endDate = new Date(
          new Date(new Date().setHours(23, 59, 59, 0)).getTime() +
          3 * 60 * 60 * 1000
        )
          .toISOString()
          .replace(/([^T]+)T([^\.]+).*/g, "$1 $2");
      }
      if (this.selected_date_filter_type == "last_30_days") {
        this.filter_values.startDate = new Date(
          new Date(new Date().setHours(0, 0, 0, 0)).getTime() +
          3 * 60 * 60 * 1000 -
          30 * 24 * 60 * 60 * 1000
        )
          .toISOString()
          .replace(/([^T]+)T([^\.]+).*/g, "$1 $2");
        this.filter_values.endDate = new Date(
          new Date(new Date().setHours(23, 59, 59, 0)).getTime() +
          3 * 60 * 60 * 1000
        )
          .toISOString()
          .replace(/([^T]+)T([^\.]+).*/g, "$1 $2");
      }
      if (this.selected_date_filter_type == "this_month") {
        let date = new Date();
        let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

        this.filter_values.startDate = new Date(
          new Date(firstDay).setHours(0, 0, 0, 0) + 3 * 60 * 60 * 1000
        )
          .toISOString()
          .replace(/([^T]+)T([^\.]+).*/g, "$1 $2");
        this.filter_values.endDate = new Date(
          new Date(lastDay).setHours(23, 59, 59, 0) + 3 * 60 * 60 * 1000
        )
          .toISOString()
          .replace(/([^T]+)T([^\.]+).*/g, "$1 $2");
      }
      if (this.selected_date_filter_type == "last_month") {
        let date = new Date();
        let firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1);
        let lastDay = new Date(date.getFullYear(), date.getMonth(), 0);

        this.filter_values.startDate = new Date(
          new Date(firstDay).setHours(0, 0, 0, 0) + 3 * 60 * 60 * 1000
        )
          .toISOString()
          .replace(/([^T]+)T([^\.]+).*/g, "$1 $2");
        this.filter_values.endDate = new Date(
          new Date(lastDay).setHours(23, 59, 59, 0) + 3 * 60 * 60 * 1000
        )
          .toISOString()
          .replace(/([^T]+)T([^\.]+).*/g, "$1 $2");
      }
    },
    getFilterOptions() {
      if (this.selected_category == "project") {
        return this.projects;
      }
      if (this.selected_category == "queue") {
        return this.queues;
      }
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getReportData: async function () {
      try {
        this.is_progress = true;

        if ([null, undefined, ""].includes(this.selected_report)) {
          this.$bvToast.toast(`${globalThis._lang("t_pleaseSelectReport")}`, {
            title: `${globalThis._lang("t_notification")}`,
            toaster: "b-toaster-bottom-right",
            solid: true,
            appendToast: false,
            variant: "warning",
          });
          return;
        }

        this.excel_url = "";
        // console.log(this.selected_report);
        this.fields = this.selected_report.fields;
        let tmp_fields = [];
        for (const item of this.fields) {
          tmp_fields.push({
            ...item,
            label: globalThis._lang(item.label),
          });
        }

        console.log("this.filter_values.selectedQueues.length", this.filter_values.selectedQueues.length)
        console.log("this.queues", this.queues)


        let url = `${globalThis.env.PROTOCOL}://${globalThis.env.API_URL}/report/v1/${this.selected_report.internal_name}`;
        if (this.selected_category == "callexper") {
          url = `${globalThis.env.PROTOCOL}://${globalThis.env.API_URL.replace(
            "api",
            "integration-callexper"
          )}/callexper/v1/${this.selected_report.internal_name}`;
        }
        var response = (
          await this.$http_in.post(url, {
            filters: {
              ...this.filter_values,
              selectedQueues: this.filter_values.selectedQueues.length == 0 ? this.queues.map(e => e.internal_name) : this.filter_values.selectedQueues
            },
            fields: tmp_fields,
            report_name: this.selected_report.internal_name,
          })
        ).data;

        this.excel_url = response.url;
        for (const item of response.data) {
          for (let key of Object.keys(item)) {
            if (["RecordingFile", "RecordingFilePath"].includes(key)) {
              console.log("key", key);
              continue;
            }
            item[key] =
              typeof item[key] == "string"
                ? item[key].split("-").join(".").replace(/ /g, "\u00a0")
                : item[key];
          }
        }
        this.items = response.data;
        if (response.extra_fields.length > 0) {
          if (
            this.selected_report.internal_name == "DataSummaryByDynamicFields"
          ) {
            this.fields = [...response.extra_fields, ...this.fields];
          } else {
            this.fields = [...this.fields, ...response.extra_fields];
          }
        }

        for (const col of this.fields) {
          if (Object.keys(this.column_labels).includes(col.label)) {
            col.label = this.column_labels[col.label];
          } else {
            col.label = globalThis._lang(col.label);
          }
          try {
            col.label =
              typeof col.label == "string"
                ? col.label.replace(/ /g, "\u00a0")
                : item[key];
          } catch (error) {
            console.log("col.label", col.label);
            console.log(error);
          }
        }

        if (response.data.length >= 500) {
          this.$bvToast.toast(
            `${globalThis._lang("t_yourReportAddToReportQueue")}`,
            {
              title: `${globalThis._lang("t_notification")}`,
              toaster: "b-toaster-bottom-right",
              solid: true,
              appendToast: false,
              variant: "warning",
            }
          );
        }
      } catch (error) {
        this.$swal({
          title: globalThis._lang("t_error"),
          text: globalThis._lang("t_AProblemOccurredwhileProcessing"),
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        console.log(error);
      } finally {
        this.is_progress = false;
      }

      // this.dialog = false;
      // this.is_progress = false;
    },
    get_users: async function () {
      var response = (await this.$http_in.get(`report/v1/User`)).data;

      this.agents = response;
    },
    secondsToHHMMSS(seconds) {
      return (
        Math.floor(seconds / 3600) +
        ":" +
        ("0" + (Math.floor(seconds / 60) % 60)).slice(-2) +
        ":" +
        ("0" + (seconds % 60)).slice(-2)
      );
    },
    GetProjects: async function () {
      var response = (await this.$http_in.get(`report/v1/Project`)).data;

      this.projects = response;
    },
    GetQueues: async function () {
      var response = (await this.$http_in.get(`spv/v1/Queue`)).data;
      // console.log('queues', response);
      this.queues = [];
      let p_queues = globalThis.permissions["queue"];
      // this.all_queues = response;
      for (const item of response) {
        if (p_queues.includes(item.internal_name)) {
          this.queues.push(item);
        }
      }
    },
    GetCampaigns: async function () {
      var response = (await this.$http_in.get(`report/v1/Campaign`)).data;

      this.campaigns = response;
    },
    GetQualityForms: async function () {
      var response = (await this.$http_in.get(`report/v1/QualityForm`)).data;

      this.quality_forms = response;
    },
    getCustomerColumns: async function () {
      // //console.log('this.agent.Project.INTERNAL_NAME', this.agent);
      var response = (await this.$http_in.get(`crm/v1/CustomerColumns`)).data;
      this.project_columns = [];
      this.columns = response[0].allkeys.sort();
      for (const item of response[0].allkeys) {
        if (
          [
            "active",
            "_id",
            "notes",
            "phones",
            "products",
            "extra_data",
            "sms",
            "attempts",
            "campaign",
          ].includes(item)
        ) {
          continue;
        }
        this.project_columns.push({
          text:
            this.column_labels[item] == undefined
              ? item
              : this.column_labels[item],
          value: item,
        });
      }
    },
    GetIVR: async function () {
      var response = (await this.$http_in.get(`report/v1/Ivr`)).data;

      this.ivr_list = response;
    },
    GetLine: async function () {
      var response = (await this.$http_in.get(`report/v1/Line`)).data;

      this.lines = response;
    },
    GetReports: async function () {
      var response = (await this.$http_in.get(`report/v1/Reports`)).data;

      this.report_list = [];
      for (const item of response) {
        if (this.report_permissions.includes(item.internal_name)) {
          item.display_name = globalThis._lang(item.display_name);
          this.report_list.push(item);
        }
      }
      console.log("report_list", this.report_list);
    },
    close_modal() {
      this.audio2 = document.getElementById("voyce_audio");
      this.audio2.pause();
      this.selected_audio = "";
    },
    openAudio(item) {
      // console.log(item);

      this.selected_audio = `${globalThis.env["PROTOCOL"]}://${globalThis.env.RECORDING_URL}/recordings/${item.RecordingFile}`;

      // if (this.$refs.player != undefined) {
      //   this.$refs.player.player.currentSrc = this.selected_audio;
      // }

      this.htmlcontent = `<!DOCTYPE html>
    <html>
        <head>
         <meta charset="utf-8" />
            <title>${item.Verdict} - ${item.BaseRemoteNumber}</title>
        </head>
        <body>
        <h4>[Tarih] => ${item.StartTime}</h4>
        <h4>[Telefon] =>  ${item.BaseRemoteNumber}</h4>
        <h4>[Sonuç Kodu] => ${item.Verdict}</h4>
        <h4>[Temsilci] => ${item.Agent}</h4>
        <h4>[Kuyruk] => ${this.queues.find((e) => e.internal_name == item.Queue)
          ? this.queues.find((e) => e.internal_name == item.Queue)
            .display_name
          : item.Queue
        }</h4>
           <audio id="voyce_audio" ref="player" controls ${globalThis.v_navbar.other_permission.includes("download_sound")
          ? ""
          : 'controlsList = "nodownload"'
        } preload="none" style = "width:100%">
              <source src="${this.selected_audio}" type="audio/mpeg">
              Your browser does not support the audio element.
           </audio>
      ${globalThis.script}
           
        </body>
    </html>
      `;
      const winUrl = URL.createObjectURL(
        new Blob([this.htmlcontent], { type: "text/html" })
      );
      window.open(
        winUrl,
        "_blank",
        `width=800,height=400,screenX=200,screenY=200`
      );
      return;
      // window.open(this.selected_audio, item.BaseRemoteNumber, "_blank");

      this.audio_modal = true;
      this.$swal({
        title: `<span class="font-weight-bolder">${item.Verdict}</span><br><span class="font-weight-bolder">  ${item.BaseRemoteNumber}</span>`,
        html: this.htmlcontent,
        showCloseButton: true,
        showCancelButton: false,
        focusConfirm: false,
        confirmButtonText: "Tamam",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        showClass: {
          popup: "animate__animated animate__flipInX",
        },
        buttonsStyling: false,
        onClose: this.close_modal,
      });
    },
    openSecLineAudio(item) {
      // console.log(item);

      this.selected_audio = `${globalThis.env["PROTOCOL"]}://${globalThis.env.RECORDING_URL}/secondline/${item.RecordingFilePath}`;

      // if (this.$refs.player != undefined) {
      //   this.$refs.player.player.currentSrc = this.selected_audio;
      // }

      this.htmlcontent = `<!DOCTYPE html>
    <html>
        <head>
         <meta charset="utf-8" />
            <title>${item.RemoteNumber}</title>
        </head>
        <body>
        <h4>[Tarih] => ${item.StartTime}</h4>
        <h4>[Telefon] =>  ${item.RemoteNumber}</h4>
        <h4>[Dahili] => ${item.LocalNumber}</h4>
           <audio id="voyce_audio" ref="player" controls ${globalThis.v_navbar.other_permission.includes("download_sound")
          ? ""
          : 'controlsList = "nodownload"'
        } preload="none" style = "width:100%">
              <source src="${this.selected_audio}" type="audio/mpeg">
              Your browser does not support the audio element.
           </audio>
      ${globalThis.script}
           
        </body>
    </html>
      `;
      const winUrl = URL.createObjectURL(
        new Blob([this.htmlcontent], { type: "text/html" })
      );
      window.open(
        winUrl,
        "_blank",
        `width=800,height=400,screenX=200,screenY=200`
      );
      return;
      // window.open(this.selected_audio, item.BaseRemoteNumber, "_blank");

      this.audio_modal = true;
      this.$swal({
        title: `<span class="font-weight-bolder">${item.Verdict}</span><br><span class="font-weight-bolder">  ${item.BaseRemoteNumber}</span>`,
        html: this.htmlcontent,
        showCloseButton: true,
        showCancelButton: false,
        focusConfirm: false,
        confirmButtonText: "Tamam",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        showClass: {
          popup: "animate__animated animate__flipInX",
        },
        buttonsStyling: false,
        onClose: this.close_modal,
      });
    },
    openNote(item) {
      this.$swal({
        title: item.Note ?? item.note ?? '',
        customClass: {
          confirmButton: "btn btn-primary",
        },
        showClass: {
          popup: "animate__animated animate__bounceIn",
        },
        buttonsStyling: false,
      });
    },
    async onexport() {
      // if (this.excel_url != '') {
      //   // window.location.assign(this.excel_url);
      //   this.is_progress = true;
      //   try {
      //     var response = await this.$http_ex.get(this.excel_url);
      //     console.log("response.status", response.status);
      //     if (response.status == 200) {
      //       var anchor = document.createElement('a');
      //       anchor.href = this.excel_url;
      //       anchor.download = this.excel_url;
      //       anchor.target = '_blank';
      //       document.body.appendChild(anchor);
      //       anchor.click();
      //       this.is_progress = false;
      //       return;
      //     }
      //     else {
      //       this.onexport();
      //     }
      //   } catch (error) {
      //     this.onexport();
      //   }

      //   // await new Promise(res => setTimeout(res, 10000));

      // }
      // return;
      // On Click Excel download button
      let _headers = {};
      for (const header of this.fields) {
        _headers[header.key] = header.label;
      }
      console.log("_headers", _headers);
      console.log("this.fields", this.fields);

      let _data = [];
      for (const row of this.items) {
        let obj = {};

        for (const column of Object.keys(_headers)) {
          let key = _headers[column] == undefined ? column : _headers[column];
          obj[key] = row[column];
        }
        _data.push(obj);
      }

      var data = this.$xlsx.utils.json_to_sheet(_data);

      // A workbook is the name given to an Excel file
      var wb = this.$xlsx.utils.book_new(); // make Workbook of Excel

      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      this.$xlsx.utils.book_append_sheet(
        wb,
        data,
        `${this.selected_report.display_name.split("/").join("-")}`
      ); // sheetAName is name of Worksheet

      // export Excel file
      this.$xlsx.writeFile(
        wb,
        `${this.selected_report.display_name.split("/").join("-")}_${this.filter_values.startDate
        }.xlsx`
      ); // name of the file is 'book.xlsx'
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.table-responsive {
  overflow: unset;
}
</style>
